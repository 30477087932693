.ib_accounts_content {
  .ib_accounts_select {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 308px);
    column-gap: 2%;
    transform: translateZ(0);

    .el-button {
      max-width: 120px;
    }

    .select {
      position: relative;

      p {
        font-size: 20px;
        font-weight: 200;
        color: $primary-purple;
        line-height: 47px;
      }

      .labelTitle {
        position: absolute;
        top: -14px;
      }

      /deep/ .el-cascader {
        margin-bottom: 32px;
        width: 100%;

        .el-cascader__label {
          font-size: 20px;
          font-weight: 200;
          color: $primary-purple;
          padding: 4px 0;
        }
      }
    }
  }

  .table_box {
    /deep/ .el-table {
      .sort-caret.ascending {
        border-bottom-color: #ffffff;
      }

      .sort-caret.descending {
        border-top-color: #ffffff;
      }

      .el-icon-arrow-down {
        color: #ffffff;
      }

      th .cell {
        min-height: unset;
        display: block;
        padding: 15px 10px;
      }
    }
  }

  .page_box {
    width: 100%;
    margin: 40px 0 0;
  }
}

@media (max-width: 600px) {
  .ib_accounts_content {
    .ib_accounts_select {
      width: auto;
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
}

@media (max-width: 550px) {
  .ib_accounts_content .ib_accounts_select .select /deep/ .el-cascader .el-cascader__label {
    font-size: 16px;
  }
}
